import React, { useState, useEffect } from "react";
import BeforeAfterSlider from "../BeforeAfterSlider/BeforeAfterSlider";
import FaqItem from "../FaqArea/FaqItem";
import ServicesDetailsAside from "./ServicesDetailsAside";

const ServicesDetailsArea = ({ service }) => {
  const [faqs, setFaqs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [expandedIndex, setExpandedIndex] = useState(null);

  useEffect(() => {
    const fetchFaqs = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/faqs`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setFaqs(data.data); // Adjust this line based on your API response structure
      } catch (error) {
        console.error('Error fetching FAQs:', error);
        setError('Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchFaqs();
  }, []);

  const handleToggle = index => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  if (!service) return null;

  return (
    <section className="services-deatails-area pt-130 pb-130">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 order-0 order-lg-2">
            <div className="services-details-wrap">
              <div className="services-details-thumb">
                <img src={`${process.env.REACT_APP_API_URL}/api/images/${service.serviceImage}`} alt={service.serviceName} />
              </div>
              <div className="services-details-content">
                <h2 className="title">{service.serviceName}</h2>
                <p>{service.serviceSubtitle}</p>
                <p>{service.serviceDescription}</p>

                {service.serviceQuality && (
                  <div className="service-quality-wrap">
                    <h4 className="title">Service Quality</h4>
                    <p>{service.serviceQuality.description}</p>
                    {service.serviceQuality.beforeAfterImages && (
                      <div id="slider1" className="beer-slider" data-start="50">
                        <BeforeAfterSlider
                          before={`${process.env.REACT_APP_API_URL}/api/images/${service.serviceQuality.beforeAfterImages.before}`}
                          after={`${process.env.REACT_APP_API_URL}/api/images/${service.serviceQuality.beforeAfterImages.after}`}
                        />
                      </div>
                    )}
                  </div>
                )}

                <div className="services-faq faq-content">
                  <div className="accordion" id="accordionExample">
                    {error ? (
                      <p>{error}</p>
                    ) : isLoading ? (
                      <p>Loading...</p>
                    ) : (
                      faqs.length > 0 ? (
                        faqs.map((x, index) => (
                          <FaqItem
                            key={index}
                            parentId={"accordionExample"}
                            item={x}
                            isExpanded={expandedIndex === index}
                            onClick={() => handleToggle(index)}
                          />
                        ))
                      ) : (
                        <p>No FAQs found.</p>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-8">
            <ServicesDetailsAside />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesDetailsArea;
