// PrivacyPolicyPage.jsx
import React, { useEffect, useState } from 'react';
import BreadcrumbArea from '../components/BreadcrumbArea/BreadcrumbArea';
import Layout from '../layouts/Layout';
import { gsapTitleAnimation } from '../lib/gsap-lib/gsapTitleAnimation';

const PrivacyPolicyPage = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    gsapTitleAnimation();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchPrivacyPolicy = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/privacy`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setPrivacyPolicy(data.data.privacyPolicy);
      } catch (error) {
        console.error('Error fetching privacy policy data:', error);
        setError('Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };
    fetchPrivacyPolicy();
  }, []);

  const renderContentWithLinks = (content) => {
    const linkRegex = /<a\s+(?:[^>]*?\s+)?href="([^"]*)"/g;
    return content.replace(
      linkRegex,
      '<a style="text-decoration: underline;" href="$1" target="_blank" rel="noopener noreferrer"'
    );
  };

  return (
    <Layout header={1} footer={1}>
      <BreadcrumbArea title={"Privacy Policy"} subtitle={"Privacy Policy"} />
      <div style={{ padding: '100px 0' }}> {/* Top and bottom spacing */}
        {isLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            Loading...
          </div>
        ) : error ? (
          <div>{error}</div>
        ) : privacyPolicy ? (
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="privacy-content" dangerouslySetInnerHTML={{ __html: renderContentWithLinks(privacyPolicy) }} />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </Layout>
  );
};

export default PrivacyPolicyPage;
