import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BlogDetailsArea from "../../components/Blogs/BlogDetailsArea/BlogDetailsArea";
import BreadcrumbArea from "../../components/BreadcrumbArea/BreadcrumbArea";
import Layout from "../../layouts/Layout";
import { gsapTitleAnimation } from "../../lib/gsap-lib/gsapTitleAnimation";

const BlogDetailsPage = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [comments, setComments] = useState([]);
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    comment: ''
  });

  useEffect(() => {
    gsapTitleAnimation();
  }, []);

  useEffect(() => {
    const fetchPost = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/articles/posts/${slug}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setPost(data.data.article);
      } catch (error) {
        console.error('Error fetching post:', error);
        setError('Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    const fetchComments = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/comments/posts/${slug}/comments`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setComments(data.data.comments);
      } catch (error) {
        console.error('Error fetching comments:', error);
      }
    };

    fetchPost();
    fetchComments();
  }, [slug]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/comments/posts/${slug}/comments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const newComment = await response.json();
      setComments([...comments, newComment.data.comment]);
      setFormData({ fullName: '', email: '', comment: '' });
    } catch (error) {
      console.error('Error submitting comment:', error);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Layout header={1} footer={1}>
      <BreadcrumbArea title={"Blog Details"} subtitle={post?.articleTitle} />
      <BlogDetailsArea 
        post={post} 
        comments={comments} 
        onCommentSubmit={handleSubmit} 
        onCommentChange={handleChange} 
        formData={formData} 
      />
    </Layout>
  );
};

export default BlogDetailsPage;
