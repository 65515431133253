import React, { useEffect, useState } from 'react';
import BlogCategories from './BlogCategories';
import BlogRecents from './BlogRecents';
import BlogSearch from './BlogSearch';
import BlogTags from './BlogTags';

const BlogSidebar = () => {
  const [recentPosts, setRecentPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    const fetchSidebarData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/articles/posts`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const postData = await response.json();
        
        setRecentPosts(postData.data.articles.slice(0, 3)); // Adjust the number of recent posts as needed

        // Process categories
        const categoriesMap = new Map();
        postData.data.articles.forEach(article => {
          categoriesMap.set(article.category, `/blog?filter=${encodeURIComponent(article.category)}`);
        });
        setCategories(Array.from(categoriesMap.keys()));

        // Process tags
        const tagsSet = new Set();
        postData.data.articles.forEach(article => {
          article.tags.forEach(tag => {
            tagsSet.add(tag);
          });
        });
        setTags(Array.from(tagsSet).slice(0, 8)); // Adjust the number of tags as needed
      } catch (error) {
        console.error('Error fetching sidebar data:', error);
      }
    };

    fetchSidebarData();
  }, []);

  return (
    <aside className="blog-sidebar">
      <div className="widget">
        <BlogSearch />
      </div>
      <div className="widget">
        <BlogRecents recentPosts={recentPosts} />
      </div>
      <div className="widget">
        <BlogCategories categories={categories} />
      </div>
      <div className="widget">
        <BlogTags tags={tags} />
      </div>
    </aside>
  );
};

export default BlogSidebar;
