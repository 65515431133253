import React, { useEffect, useState } from "react";
import BlogAreaTwoItem from "./BlogAreaTwoItem";

const BlogPageArea = () => {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 3;

  useEffect(() => {
    const fetchPosts = async () => {
      setIsLoading(true);

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/articles/posts`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setPosts(data.data.articles);
      } catch (error) {
        console.error("Error fetching posts:", error);
        setError("Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchPosts();
  }, []);

  // Calculate the posts for the current page
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <section className="inner-blog-area pt-130 pb-130">
      <div className="container">
        <div className="row justify-content-center">
          {currentPosts.map((x, index) => (
            <div key={index} className="col-lg-6 col-md-8">
              <BlogAreaTwoItem item={x} />
            </div>
          ))}
        </div>

        <div className="pagination mt-4">
          {[...Array(Math.ceil(posts.length / postsPerPage)).keys()].map((number) => (
            <button
              key={number + 1}
              onClick={() => paginate(number + 1)}
              className={`btn ${currentPage === number + 1 ? "btn-primary" : "btn-secondary"}`}
            >
              {number + 1}
            </button>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BlogPageArea;
