import React, { useState, useEffect } from "react";
import ContactForm from "./ContactForm";

const ContactPageArea = () => {
  const [contactData, setContactData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchContactData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/contact`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setContactData(data.data.contact);
      } catch (error) {
        console.error('Error fetching contact data:', error);
        setError('Failed to fetch contact data.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchContactData();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!contactData) {
    return null;
  }

  return (
    <section className="contact-area pt-130 pb-130">
      <div className="container">
        <div className="inner-contact-info-wrap">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-10">
              <div className="inner-contact-info-item">
                <div className="icon">
                  <i className="fas fa-phone-volume"></i>
                </div>
                <div className="content">
                  <a href={`tel:${contactData.firstPhoneNumber}`}>{contactData.firstPhoneNumber}</a>
                  <a href={`tel:${contactData.secondPhoneNumber}`}>{contactData.secondPhoneNumber}</a>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-10">
              <div className="inner-contact-info-item">
                <div className="icon">
                  <i className="fas fa-envelope"></i>
                </div>
                <div className="content">
                  <a href={`mailto:${contactData.firstEmail}`}>{contactData.firstEmail}</a>
                  <a href={`mailto:${contactData.secondEmail}`}>{contactData.secondEmail}</a>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-10">
              <div className="inner-contact-info-item">
                <div className="icon">
                  <i className="fas fa-map-marker-alt"></i>
                </div>
                <div className="content">
                  <p>
                    {contactData.firstAddress} <br />
                    {contactData.secondAddress}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contact-form-area">
          <div className="row align-items-center g-0">
            <div className="col-lg-6">
              <div className="contact-img">
                <img src="/img/images/contact_img.jpg" alt="" />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="contact-content">
                <h2 className="title">Contact for Services</h2>
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactPageArea;
