import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BrandArea from "../../components/BrandArea/BrandArea";
import BreadcrumbArea from "../../components/BreadcrumbArea/BreadcrumbArea";
import ServicesDetailsArea from "../../components/ServicesDetailsArea/ServicesDetailsArea";
import Layout from "../../layouts/Layout";
import { gsapTitleAnimation } from "../../lib/gsap-lib/gsapTitleAnimation";

const ServicesDetailsPage = () => {
  const { slug } = useParams();
  const [serviceData, setServiceData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    gsapTitleAnimation();
  }, []);

  useEffect(() => {
    const fetchServiceData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/services/services/${slug}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setServiceData(data.data.service);
      } catch (error) {
        console.error('Error fetching service data:', error);
        setError('Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchServiceData();
  }, [slug]);

  return (
    <>
      <Layout header={1} footer={1}>
        <BreadcrumbArea
          title={"Single Services"}
          subtitle={"Single Services"}
        />
        {isLoading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <ServicesDetailsArea service={serviceData} />
        )}
        <BrandArea className={"inner-brand-area pb-70"} />
      </Layout>
    </>
  );
};

export default ServicesDetailsPage;
