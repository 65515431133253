import React from 'react';
import { Link } from 'react-router-dom';

const BlogRecents = ({ recentPosts }) => {
  return (
    <>
      <h4 className="widget-title">Recent post</h4>
      <div className="rc-post-list">
        {recentPosts.map((item, index) => (
          <div key={index} className="rc-post-item">
            <div className="thumb">
              <Link to={`/blog/${item.slug}`}>
                <img src={`${process.env.REACT_APP_API_URL}/api/images/${item.imageCover}`} alt="" />
              </Link>
            </div>
            <div className="content">
              <span className="date">
                <i className="far fa-calendar-alt" />
                {new Date(item.publishedAt).toLocaleDateString('en-GB', {
                  day: '2-digit',
                  month: 'short',
                  year: 'numeric'
                }).replace(/ /g, '.')}
              </span>
              <h5 className="title">
                <Link to={`/blog/${item.slug}`}>
                  {item.articleTitle.length > 15 ? `${item.articleTitle.substring(0, 25)}...` : item.articleTitle}
                </Link>
              </h5>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default BlogRecents;
