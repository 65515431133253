import React, { useEffect, useState } from "react";
import cn from "classnames";

const HeaderTop = ({ className }) => {
  const [news, setNews] = useState([]);
  const [workingHours, setWorkingHours] = useState("Sun-Thu (8.00AM - 6.00PM)");
  const [currentNewsIndex, setCurrentNewsIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const fetchHeaderData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/news/header`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setNews(data.data.news);
        setWorkingHours(data.data.workingHours);
      } catch (error) {
        console.error("Error fetching header data:", error);
      }
    };

    fetchHeaderData();
  }, []);

  useEffect(() => {
    if (news.length > 0) {
      const interval = setInterval(() => {
        setIsVisible(false);
        setTimeout(() => {
          setCurrentNewsIndex((prevIndex) => (prevIndex + 1) % news.length);
          setIsVisible(true);
        }, 500); // This timeout should match the duration of the fade-out animation
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [news]);

  return (
    <div className={cn("header-top", className)}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="header-top-left">
              {news.length > 0 && (
                <div className="news-item">
                  <i className="fas fa-fire-alt"></i>
                  <span className={`news-message ${isVisible ? "visible" : "hidden"}`}>
                    {news[currentNewsIndex].message}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="header-top-right">
              <span>
                <i className="far fa-clock"></i>Working Hours: {workingHours}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderTop;
