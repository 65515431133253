import React from "react";

const BlogDetailsComments = ({ comments }) => {
  return (
    <div className="latest-comments">
      {comments.length > 0 && (
        <>
          <h2 className="cs-font_50 cs-m0 mb-4">Comments</h2>
          <div className="cs-comments">
            {comments.map((comment, index) => (
              <div key={index} className="cs-comment" style={{ marginBottom: '20px' }}>
                <div className="cs-comment_info" style={{ paddingBottom: '10px', borderBottom: '1px solid #ccc' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                    <h6 className="cs-comment_author" style={{ fontSize: '17px', color: '#1239ac', margin: 0 }}>{comment.fullName}</h6>
                    <span className="cs-comment_date" style={{ fontSize: '12px', color: '#999', marginLeft: '10px' }}>{new Date(comment.createdAt).toLocaleDateString()}</span>
                  </div>
                  <p style={{ marginBottom: '10px', fontSize: '16px' }}>{comment.comment}</p>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default BlogDetailsComments;
