import React, { useEffect, useState } from "react";
import TestimonialAreaTwoItem from "./TestimonialAreaTwoItem";
import SlickSlider from "../SlickSlider/SlickSlider";
import cn from "classnames";

const TestimonialAreaTwo = ({ className, headerClassName, src }) => {
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/testimonials/testimonials`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setTestimonials(data.data.testimonials);
      } catch (error) {
        console.error('Error fetching testimonials:', error);
      }
    };

    fetchTestimonials();
  }, []);

  const slick_settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <section className={cn(className, "testimonial-bg")} data-background={src}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div
              className={cn(
                headerClassName,
                "section-title-two text-center mb-65 tg-heading-subheading"
              )}
            >
              <span className="sub-title">What We’re Offering</span>
              <h2 className="title tg-element-title">
                Providing the Best Services for Our Customers
              </h2>
            </div>
          </div>
        </div>

        <div className="row testimonial-active-two">
          <SlickSlider settings={slick_settings}>
            {testimonials.map((x, index) => (
              <div key={index} className="col-lg-4">
                <TestimonialAreaTwoItem item={x} />
              </div>
            ))}
          </SlickSlider>
        </div>
      </div>
    </section>
  );
};

export default TestimonialAreaTwo;
