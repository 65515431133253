import React, { useEffect, useState } from 'react';
import BlogAreaTwoItem from './BlogAreaTwoItem';

const BlogAreaTwo = () => {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/articles/posts`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setPosts(data.data.articles);
      } catch (error) {
        console.error('Error fetching posts:', error);
        setError('Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchPosts();
  }, []);

  if (isLoading) {
    return (
      <section className="blog-area-two pt-125 pb-60">
        <div className="container">
          <div className="row justify-content-center">
            <p>Loading...</p>
          </div>
        </div>
      </section>
    );
  }

  if (error) {
    return (
      <section className="blog-area-two pt-125 pb-60">
        <div className="container">
          <div className="row justify-content-center">
            <p>{error}</p>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="blog-area-two pt-125 pb-60">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="section-title-two text-center mb-60 tg-heading-subheading animation-style2">
              <span className="sub-title">Latest News & Articles</span>
              <h2 className="title tg-element-title">
                Learn More from Our <br />
                Blog Posts
              </h2>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          {posts.slice(0, 2).map((x, index) => (
            <div key={index} className="col-lg-6 col-md-8">
              <BlogAreaTwoItem item={x} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BlogAreaTwo;
