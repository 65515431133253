import React, { useEffect, useState } from "react";
import SlickSlider from "../SlickSlider/SlickSlider";
import cn from "classnames";

const BrandArea = ({ className }) => {
  const [logos, setLogos] = useState([]);

  useEffect(() => {
    const fetchLogos = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/logo/brands`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setLogos(data.data.logos);
      } catch (error) {
        console.error("Error fetching logos:", error);
      }
    };

    fetchLogos();
  }, []);

  const slick_settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: false,
    slidesToShow: 6,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className={cn(className ? className : "brand-area pb-130")}>
      <div className="container">
        <div className="row brand-active">
          <SlickSlider settings={slick_settings}>
            {logos.map((logo, index) => (
              <div key={index} className="col-12">
                <div className="brand-item">
                  <a href={logo.link} target="_blank" rel="noopener noreferrer">
                    <img src={`${process.env.REACT_APP_API_URL}/api/images/${logo.logo}`} alt={logo.name} className="brand-logo"/>
                  </a>
                </div>
              </div>
            ))}
          </SlickSlider>
        </div>
      </div>
    </div>
  );
};

export default BrandArea;
