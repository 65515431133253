import React from 'react';

const BlogCategories = ({ categories }) => {
  return (
    <>
      <h4 className="widget-title">Categories</h4>
      <div className="cat-list-wrap">
        <ul className="list-wrap">
          {categories.map((category, index) => (
            <li key={index}>
              <a href={`/blog?filter=${encodeURIComponent(category)}`}>{category}</a>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default BlogCategories;
