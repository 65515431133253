import React from "react";

const TestimonialAreaTwoItem = ({ item }) => {
  const truncateText = (text, length) => {
    if (text.length > length) {
      return text.substring(0, length) + "...";
    }
    return text;
  };

  return (
    <div className="testimonial-item-two">
      <div className="testimonial-icon-two">
        <i className="fas fa-quote-right"></i>
      </div>

      <div className="testimonial-content-two">
        <p>{truncateText(item.quote, 200)}</p> {/* Adjust the length as needed */}

        <div className="testimonial-avatar-info">
          <div className="avatar-thumb">
            <img src={`${process.env.REACT_APP_API_URL}/api/images/${item.image}`} alt={item.name} />
          </div>

          <div className="avatar-content">
            <h4 className="title">{item.name}</h4>

            <p>{item.positionAndCompany}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialAreaTwoItem;
