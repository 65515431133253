import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BreadcrumbArea from "../../components/BreadcrumbArea/BreadcrumbArea";
import TeamDetailsArea from "../../components/Team/TeamDetailsArea/TeamDetailsArea";
import Layout from "../../layouts/Layout";
import { gsapTitleAnimation } from "../../lib/gsap-lib/gsapTitleAnimation";

const TeamDetailsPage = () => {
  const { slug } = useParams(); // Assuming the URL contains a slug parameter
  const [teamMember, setTeamMember] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    gsapTitleAnimation();

    const fetchTeamMember = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/team/teams/${slug}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setTeamMember(data.data.teamMember);
      } catch (error) {
        console.error('Error fetching team member:', error);
        setError('Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchTeamMember();
  }, [slug]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Layout header={1} footer={1}>
      <BreadcrumbArea title={"Team Details"} subtitle={"Team Details"} />
      <TeamDetailsArea teamMember={teamMember} />
    </Layout>
  );
};

export default TeamDetailsPage;
