import { useEffect } from "react";
import { useLocation, Routes, Route } from "react-router-dom";
import AnimatedCursor from "react-animated-cursor";
import Home from "./pages/Homes/Home";
import WOW from "wow.js";
import AOS from "aos";
import AboutUsPage from "./pages/AboutUsPage";
import ServicesPageOne from "./pages/Services/Services";
import ServicesDetailsPage from "./pages/Services/ServicesDetailsPage";
import TeamPage from "./pages/TeamPages/TeamPage";
import TeamDetailsPage from "./pages/TeamPages/TeamDetailsPage";
import EstimatePage from "./pages/EstimatePage";
import BlogPage from "./pages/BlogPages/BlogPage";
import BlogDetailsPage from "./pages/BlogPages/BlogDetailsPage";
import ContactPage from "./pages/ContactPage";
import TermsOfUse from "./pages/TermsOfUse";
import PrivacyPolicy from "./pages/PrivacyPolicy";

function App() {
  // wow js scroll
  useEffect(() => {
    const wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: false,
      live: true,
    });
    wow.init();
  }, []);

  // aos scroll
  useEffect(() => {
    AOS.init({ duration: 1000, mirror: true, once: true, disable: "mobile" });
  }, []);

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, [pathname]);

  return (
    <>
      <AnimatedCursor
        innerSize={10}
        outerSize={32}
        color="0, 124, 251"
        outerAlpha={0}
        innerScale={0}
        outerScale={0}
        trailingSpeed={1000}
        clickables={[
          "a",
          'input[type="text"]',
          'input[type="email"]',
          'input[type="number"]',
          'input[type="submit"]',
          'input[type="image"]',
          "label[for]",
          "select",
          "option",
          "textarea",
          "button",
          ".link",
          ".swiper-pagination-bullet",
        ]}
      />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUsPage />} />
        <Route path="/services" element={<ServicesPageOne />} />\
        <Route path="/services/:slug" element={<ServicesDetailsPage />} />
        <Route path="/team" element={<TeamPage />} />
        <Route path="/team/:slug" element={<TeamDetailsPage />} />
        <Route path="/estimate" element={<EstimatePage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/blog/:slug" element={<BlogDetailsPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/TermsOfUse" element={<TermsOfUse />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
      </Routes>
    </>
  );
}

export default App;
