import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import FooterBottom from "./FooterBottom";

const FooterOne = () => {
  const [contactData, setContactData] = useState(null);
  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    const fetchContactData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/contact`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setContactData(data.data.contact);
      } catch (error) {
        console.error("Error fetching contact data:", error);
        setError("Failed to fetch contact data.");
      }
    };

    const fetchServicesData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/services/services`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setServices(data.data.services.slice(0, 6)); // Fetch only the first six services
      } catch (error) {
        console.error("Error fetching services data:", error);
        setError("Failed to fetch services data.");
      }
    };

    fetchContactData();
    fetchServicesData();
    setIsLoading(false);
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;

    try {
      const response = await fetch(form.action, {
        method: form.method,
        body: new FormData(form),
        headers: {
          Accept: "application/json",
        },
      });

      if (response.ok) {
        setIsSubmitted(true); // Change the button text to "Submitted"
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!contactData || services.length === 0) {
    return null;
  }

  return (
    <footer>
      <div className="footer-area footer-bg">
        <div className="footer-top">
          <div className="container">
            <div className="footer-logo-area">
              <div className="row align-items-center">
                <div className="col-md-4">
                  <div className="logo">
                    <Link to="/">
                      <img src="/img/logo/w_logo.svg" alt="Super Laundry Logo" />
                    </Link>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="footer-social-menu">
                    <ul className="list-wrap">
                      <li>
                        <a href="https://www.facebook.com/people/Super-Laundry">Facebook</a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/super_laundry_oman">Instagram</a>
                      </li>
                      <li>
                        <a href="https://wa.me/+96890603087">Whatsapp</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <div className="footer-widget">
                  <div className="fw-title">
                    <h4 className="title">Contact Us</h4>
                  </div>
                  <div className="footer-content">
                    <p>{contactData.firstAddress}</p>
                    <div className="footer-contact">
                      <ul className="list-wrap">
                        <li className="phone-addess">
                          <i className="fas fa-phone-alt"></i>
                          <a href={`tel:${contactData.firstPhoneNumber}`}>{contactData.firstPhoneNumber}</a>
                        </li>
                        <li className="email-addess">
                          <a href={`mailto:${contactData.firstEmail}`}>{contactData.firstEmail}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-sm-6">
                <div className="footer-widget">
                  <div className="fw-title">
                    <h4 className="title">Our Links</h4>
                  </div>
                  <div className="fw-link-list">
                    <ul className="list-wrap">
                      <li>
                        <Link to="/about">About</Link>
                      </li>
                      <li>
                        <Link to="/team">Meet Our Team</Link>
                      </li>
                      <li>
                        <Link to="/services">What We Do</Link>
                      </li>
                      <li>
                        <Link to="/blog">News</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-sm-6">
                <div className="footer-widget">
                  <div className="fw-title">
                    <h4 className="title">Our Services</h4>
                  </div>
                  <div className="fw-link-list">
                    <ul className="list-wrap">
                      {services.map((service, index) => (
                        <li key={index}>
                          <Link to={`/services/${service.slug}`}>{service.serviceName}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-sm-6">
                <div className="footer-widget">
                  <div className="fw-title">
                    <h4 className="title">Newsletter</h4>
                  </div>
                  <div className="footer-newsletter">
                    <p>
                      Subscribe to our newsletter to get our latest updates & news
                    </p>
                    <form
                      action="https://formspree.io/f/xgvwwrjw"
                      method="POST"
                      onSubmit={handleFormSubmit}
                    >
                      <input type="email" name="email" placeholder="Your mail address" required />
                      <button type="submit" className="btn">
                        {isSubmitted ? (
                          <>
                            <i className="fas fa-check-circle"></i> Submitted
                          </>
                        ) : (
                          "Subscribe"
                        )}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* footer bottom */}
        <FooterBottom />
      </div>
    </footer>
  );
};

export default FooterOne;
