import React from "react";

const BlogDetailsForm = ({ onSubmit, onChange, formData }) => {
  return (
    <form className="comment-form" onSubmit={onSubmit}>
      <div className="row">
        <div className="col-md-6">
          <div className="form-grp">
            <input 
              type="text" 
              placeholder="Name *" 
              name="fullName"
              value={formData.fullName}
              onChange={onChange}
              required 
            />
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-grp">
            <input 
              type="email" 
              placeholder="Email *" 
              name="email"
              value={formData.email}
              onChange={onChange}
              required 
            />
          </div>
        </div>
      </div>

      <div className="form-grp">
        <textarea 
          name="comment" 
          placeholder="Comment *" 
          value={formData.comment}
          onChange={onChange}
          required 
        />
      </div>

      <button type="submit" className="btn btn-two">
        Post Comment
      </button>
    </form>
  );
};

export default BlogDetailsForm;
