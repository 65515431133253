import React, { useState } from "react";

const TeamDetailsForm = () => {
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);

    try {
      const response = await fetch("https://formspree.io/f/xeojjvwb", {
        method: "POST",
        body: formData,
        headers: {
          'Accept': 'application/json',
        },
      });

      if (response.ok) {
        setSubmitted(true);
      } else {
        alert("Failed to submit the form. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      alert("An error occurred while submitting the form. Please try again.");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-6">
          <div className="form-grp">
            <input type="text" name="name" placeholder="Your Name" required />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-grp">
            <input type="email" name="email" placeholder="Email address" required />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-grp">
            <input type="text" name="phone" placeholder="Phone number" required />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-grp">
            <input type="text" name="subject" placeholder="Subject" required />
          </div>
        </div>
      </div>
      <div className="form-grp">
        <textarea name="message" placeholder="Write message" required></textarea>
      </div>
      <button type="submit" className="btn btn-two" disabled={submitted}>
        {submitted ? (
          <>
            <i className="fas fa-check-circle" style={{ color: "green" }}></i> Submitted
          </>
        ) : (
          "Send a message"
        )}
      </button>
    </form>
  );
};

export default TeamDetailsForm;
