import React from "react";
import { Link } from "react-router-dom";

const ServicesAreaFourItem = ({ item }) => {
  return (
    <div className="services-item-four">
      <div className="services-thumb-four">
        <Link to={`/services/${item.slug}`}>
          <img src={`${process.env.REACT_APP_API_URL}/api/images/${item.serviceImage}`} alt={item.serviceName} />
        </Link>
      </div>

      <div className="services-content-four">
        <div className="services-icon">
          <img src={`${process.env.REACT_APP_API_URL}/api/images/${item.serviceThumbnail}`} alt={item.serviceName} />
        </div>

        <h3 className="title">
          <Link to={`/services/${item.slug}`}>{item.serviceName}</Link>
        </h3>
        <p>{item.serviceSubtitle}</p>
      </div>
    </div>
  );
};

export default ServicesAreaFourItem;
