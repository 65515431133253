import React from "react";

const ContactForm = () => {
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);

    fetch("https://formspree.io/f/xpwaakgz", {
      method: "POST",
      body: formData,
      headers: {
        'Accept': 'application/json'
      }
    })
      .then(response => {
        if (response.ok) {
          window.location.href = '/';
        } else {
          alert("There was a problem with the submission.");
        }
      })
      .catch(error => {
        console.error("Error:", error);
        alert("There was an error submitting the form.");
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-6">
          <div className="form-grp">
            <input type="text" name="name" placeholder="Your Name" required />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-grp">
            <input type="email" name="email" placeholder="Email address" required />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-grp">
            <input type="text" name="phone" placeholder="Phone number" required />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-grp">
            <input type="text" name="subject" placeholder="Subject" required />
          </div>
        </div>
      </div>
      <div className="form-grp">
        <textarea name="message" placeholder="Write message" required></textarea>
      </div>
      <button type="submit" className="btn">
        Send a message
      </button>
    </form>
  );
};

export default ContactForm;
