import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const ServicesAreaAll = () => {
  const [servicesData, setServicesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchServicesData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/services/services`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const filteredServices = data.data.services.filter(service => !service.isMain);
        const sortedServices = filteredServices.sort((a, b) => b.sortOrder - a.sortOrder);
        setServicesData(sortedServices);
      } catch (error) {
        console.error('Error fetching services data:', error);
        setError('Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchServicesData();
  }, []);

  const truncateText = (text, length) => {
    if (text.length > length) {
      return text.substring(0, length) + "...";
    }
    return text;
  };

  return (
    <section className="services-area-three inner-services-two pt-130 pb-90">
      <div className="container custom-container-two">
        <div className="services-item-wrap-two">
          <div className="row">
            {isLoading ? (
              <p>Loading...</p>
            ) : error ? (
              <p>{error}</p>
            ) : (
              servicesData.map((item, index) => (
                <div key={index} className="col-xl-4 col-md-6">
                  <div className="services-item-three">
                    <div className="services-thumb-three">
                      <Link to={`/services/${item.slug}`}>
                        <img src={`${process.env.REACT_APP_API_URL}/api/images/${item.serviceImage}`} alt={item.serviceName} />
                      </Link>
                    </div>
                    <div className="services-content-three">
                      <div className="icon">
                        <img src={`${process.env.REACT_APP_API_URL}/api/images/${item.serviceThumbnail}`} alt={item.serviceName} />
                      </div>
                      <h2 className="title">
                        <Link to={`/services/${item.slug}`}>{item.serviceName}</Link>
                      </h2>
                      <p>{truncateText(item.serviceSubtitle, 75)}</p>
                      <Link to={`/services/${item.slug}`} className="btn btn-two">
                        Read more
                      </Link>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesAreaAll;
