import React from "react";

const EstimateForm = () => {
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);

    fetch("https://formspree.io/f/xkgwwklg", {
      method: "POST",
      body: formData,
      headers: {
        'Accept': 'application/json'
      }
    })
      .then(response => {
        if (response.ok) {
          window.location.href = '/';
        } else {
          alert("There was a problem with the submission.");
        }
      })
      .catch(error => {
        console.error("Error:", error);
        alert("There was an error submitting the form.");
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-sm-6">
          <div className="form-grp">
            <input type="text" name="firstName" placeholder="First Name*" required />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-grp">
            <input type="text" name="lastName" placeholder="Last Name*" required />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-grp">
            <input type="text" name="phoneNumber" placeholder="Phone number*" required />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-grp">
            <input type="email" name="email" placeholder="Email*" required />
          </div>
        </div>
      </div>
      <div className="form-grp">
        <input type="text" name="companyName" placeholder="Company Name*" required />
      </div>
      <div className="form-grp select-grp">
        <select id="shortByOne" name="service" aria-label="Select Services" required>
          <option value="">Select Services*</option>
          <option>Hotels Service</option>
          <option>Restaurants Service</option>
          <option>Others Service</option>
        </select>
      </div>
      <div className="form-grp select-grp">
        <select id="shortByThree" name="referralSource" aria-label="How did you hear about us?" required>
          <option value="">How did you hear about us?*</option>
          <option>Internet Search</option>
          <option>Referral</option>
          <option>Advertisement</option>
          <option>Other</option>
        </select>
      </div>
      <div className="form-grp">
        <textarea name="comments" placeholder="Comments/Questions*" required></textarea>
      </div>
      <button type="submit" className="btn btn-two">
        Submit Now
      </button>
    </form>
  );
};

export default EstimateForm;
