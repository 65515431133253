import React, { useState, useEffect } from 'react';
import FaqItem from './FaqItem';

const FaqAreaOne = () => {
  const [faqs, setFaqs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [expandedIndex, setExpandedIndex] = useState(null);

  const fetchFaqs = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/faqs`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setFaqs(data.data); // Adjust this line based on your API response structure
    } catch (error) {
      console.error('Error fetching FAQs:', error);
      setError('Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchFaqs();
  }, []);

  const handleToggle = index => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <section className="faq-area pt-90 overflow-hidden mb-4">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="faq-content">
              <div className="section-title-two mb-40 tg-heading-subheading animation-style2">
                <span className="sub-title">Super Laundry FAQs</span>
                <h2 className="title tg-element-title">
                  Frequently Asked <br />
                  Question from Our Clients
                </h2>
              </div>

              <div className="accordion" id="accordionExample">
                {faqs.length > 0 ? (
                  faqs.map((x, index) => (
                    <FaqItem
                      key={index}
                      parentId={"accordionExample"}
                      item={x}
                      isExpanded={expandedIndex === index}
                      onClick={() => handleToggle(index)}
                    />
                  ))
                ) : (
                  <p>No FAQs found.</p>
                )}
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-8">
            <div className="faq-img">
              <img src="/img/images/faq_img.png" data-aos="fade-left" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqAreaOne;
