import React, { useEffect, useState } from "react";
import { bgImgFromData } from "../../lib/helpers";
import { Link } from "react-router-dom";

const ServicesDetailsAside = () => {
  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    bgImgFromData();

    const fetchServices = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/services/services`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setServices(data.data.services.slice(0, 6)); // Fetch only the first 6 services
      } catch (error) {
        console.error("Error fetching services:", error);
        setError("Failed to fetch services.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchServices();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <aside className="services-sidebar">
      <div className="widget">
        <div className="services-cat-list">
          <h4 className="title">Top Services</h4>
          <ul className="list-wrap">
            {services.map((service, index) => (
              <li key={index}>
                <Link to={`/services/${service.slug}`}>{service.serviceName}</Link>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="widget">
        <div className="download-wrap" data-background="/img/images/download_bg.jpg">
          <span>PDF Files</span>
          <h2 className="title">Download Brochures</h2>
          <a href="/path/to/company-profile.pdf" download>
            Company Profile <i className="far fa-file-pdf"></i>
          </a>
          <a href="/path/to/company-brochure.pdf" download>
            Company Brochure <i className="far fa-file-pdf"></i>
          </a>
        </div>
      </div>
    </aside>
  );
};

export default ServicesDetailsAside;
