import React from "react";

const TeamDetailsSidebar = ({ teamMember }) => {
  return (
    <div className="team-details-img">
      <img src={`${process.env.REACT_APP_API_URL}/api/images/${teamMember.image}`} alt={teamMember.name} />
      <div className="team-details-info">
        <h2 className="title">{teamMember.name}</h2>
        <span>{teamMember.position}</span>
        <div className="team-details-social">
          <ul className="list-wrap">
            {teamMember.facebook && (
              <li>
                <a href={teamMember.facebook}>
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
            )}
            {teamMember.twitter && (
              <li>
                <a href={teamMember.twitter}>
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
            )}
            {teamMember.linkedin && (
              <li>
                <a href={teamMember.linkedin}>
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </li>
            )}
            {teamMember.instagram && (
              <li>
                <a href={teamMember.instagram}>
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
            )}
            {teamMember.behance && (
              <li>
                <a href={teamMember.behance}>
                  <i className="fab fa-behance"></i>
                </a>
              </li>
            )}
          </ul>
        </div>

        <div className="info-list">
          <ul className="list-wrap">
            {teamMember.website && (
              <li>
                Website : <a href={teamMember.website}>{teamMember.website}</a>
              </li>
            )}
            {teamMember.phone && (
              <li>
                Phone : <a href={`tel:${teamMember.phone}`}>{teamMember.phone}</a>
              </li>
            )}
            {teamMember.email && (
              <li>
                Email : <a href={`mailto:${teamMember.email}`}>{teamMember.email}</a>
              </li>
            )}
            {teamMember.address && (
              <li>
                Address : <span>{teamMember.address}</span>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TeamDetailsSidebar;
