import React, { useEffect, useState } from "react";
import EstimateForm from "./EstimateForm";

const EstimateArea = () => {
  const [contactInfo, setContactInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchContactInfo = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/contact`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setContactInfo(data.data.contact);
      } catch (error) {
        console.error("Error fetching contact info:", error);
        setError("Failed to fetch contact info.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchContactInfo();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const renderLocationItem = (title, address, phoneNumber) => (
    <div className="col-sm-6">
      <div className="loction-item">
        <div className="icon">
          <i className="fas fa-map-marker-alt"></i>
        </div>
        <div className="content">
          <h2 className="title">{title}</h2>
          <p>{address}</p>
          <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
        </div>
      </div>
    </div>
  );

  return (
    <section className="estimate-area pt-130 pb-130">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="estimate-img-wrap">
              <img src="/img/images/estimate_img.jpg" alt="" />
              
              <div className="office-loction-wrap">
                <div className="row justify-content-center">
                  {contactInfo.firstAddress && renderLocationItem(contactInfo.firstCountryName, contactInfo.firstAddress, contactInfo.firstPhoneNumber)}
                  {contactInfo.secondAddress && renderLocationItem(contactInfo.secondCountryName, contactInfo.secondAddress, contactInfo.secondPhoneNumber)}
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="estimate-form">
              <h2 className="title">Schedule Free Estimate</h2>
              {/* form */}
              <EstimateForm />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EstimateArea;
