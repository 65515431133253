import React from 'react';

const BlogTags = ({ tags }) => {
  return (
    <>
      <h4 className="widget-title">Tags</h4>
      <div className="tag-list-wrap">
        <ul className="list-wrap">
          {tags.map((tag, index) => (
            <li key={index}>
              <a href={`/blog?filter=${encodeURIComponent(tag)}`}>{tag}</a>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default BlogTags;
