import React from "react";
import cn from "classnames";

const FaqItem = ({ item, parentId, isExpanded, onClick }) => {
  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id={`heading${item.id}`}>
        <button
          className={cn("accordion-button", { collapsed: !isExpanded })}
          type="button"
          onClick={onClick}
          aria-expanded={isExpanded}
          aria-controls={`collapse${item.id}`}
        >
          {item.question}
        </button>
      </h2>
      <div
        id={`collapse${item.id}`}
        className={cn("accordion-collapse collapse", { show: isExpanded })}
        aria-labelledby={`heading${item.id}`}
        data-bs-parent={`#${parentId}`}
      >
        <div className="accordion-body">
          <p>{item.answer}</p>
        </div>
      </div>
    </div>
  );
};  

export default FaqItem;
