import React from "react";
import { Link } from "react-router-dom";

const TeamOneItem = ({ item }) => {
  return (
    <div className="team-item">
      <div className="team-thumb">
        <Link to={`/team/${item.slug}`}>
          <img src={`${process.env.REACT_APP_API_URL}/api/images/${item.image}`} alt={item.name} />
        </Link>
      </div>

      <div className="team-content">
        <h3 className="title">
          <Link to={`/team/${item.slug}`}>{item.name}</Link>
        </h3>

        <span>{item.position}</span>

        <div className="team-social">
          <span className="social-toggle-icon">
            <i className="fas fa-share-alt"></i>
          </span>
          <ul className="list-wrap">
            {item.facebook && (
              <li>
                <a href={item.facebook}>
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
            )}
            {item.twitter && (
              <li>
                <a href={item.twitter}>
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
            )}
            {item.linkedin && (
              <li>
                <a href={item.linkedin}>
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </li>
            )}
            {item.instagram && (
              <li>
                <a href={item.instagram}>
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TeamOneItem;
