import { Link, useLocation } from "react-router-dom";
import cn from "classnames";

const HeaderNavMenu = () => {
  const { pathname } = useLocation();

  const isActiveClassName = (path) => {
    return path === pathname ? "active" : "";
  };

  return (
    <div className="navbar-wrap main-menu d-none d-lg-flex">
      <ul className="navigation">
        
        <li className={cn(isActiveClassName("/"))}>
          <Link to="/">Home</Link>
        </li>
        <li className={cn(isActiveClassName("/about"))}>
          <Link to="/about">About Us</Link>
        </li>
        <li className={cn(isActiveClassName("/services"))}>
          <Link to="/services">Services</Link>
        </li>
        <li className={cn(isActiveClassName("/blog"))}>
          <Link to="/blog">Blog</Link>
        </li>
        <li className={cn(isActiveClassName("/estimate"))}>
          <Link to="/estimate">Booking</Link>
        </li>
        <li className={cn(isActiveClassName("/contact"))}>
          <Link to="/contact">contacts</Link>
        </li>
      </ul>
    </div>
  );
};

export default HeaderNavMenu;
