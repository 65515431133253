import React, { useEffect, useState } from "react";
import { bgImgFromData } from "../../lib/helpers";
import { jarallax } from "jarallax";
import ServicesAreaThreeItem from "./ServicesAreaThreeItem";
import SlickSlider from "../SlickSlider/SlickSlider";

const ServicesAreaThree = () => {
  const [servicesData, setServicesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    bgImgFromData();
  }, []);

  useEffect(() => {
    jarallax(document.querySelectorAll(".jarallax"), {
      speed: 0.2,
    });
  }, []);

  useEffect(() => {
    const fetchServicesData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/services/services`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const filteredServices = data.data.services.filter(service => !service.isMain);
        const sortedServices = filteredServices.sort((a, b) => b.sortOrder - a.sortOrder);
        setServicesData(sortedServices);
      } catch (error) {
        console.error('Error fetching services data:', error);
        setError('Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchServicesData();
  }, []);

  const slick_settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <section className="services-area-three pt-125">
      <div
        className="services-bg jarallax"
        data-background="/img/bg/services_bg.jpg"
      ></div>
      <div className="container custom-container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-7">
            <div className="section-title-two white-title text-center mb-65 tg-heading-subheading animation-style2">
              <span className="sub-title">What We’re Offering</span>
              <h2 className="title tg-element-title">
                Providing the Best Services <br />
                for Our Customers
              </h2>
            </div>
          </div>
        </div>

        <div className="services-item-wrap-two">
          <div className="row services-active">
            {isLoading ? (
              <p>Loading...</p>
            ) : error ? (
              <p>{error}</p>
            ) : (
              <SlickSlider settings={slick_settings}>
                {servicesData.map((service) => (
                  <div key={service._id} className="col-lg-3">
                    <ServicesAreaThreeItem item={service} />
                  </div>
                ))}
              </SlickSlider>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesAreaThree;
