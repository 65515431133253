import React from "react";
import { Link } from "react-router-dom";

const BlogAreaTwoItem = ({ item }) => {
  return (
    <div className="blog-item-two">
      <div className="blog-thumb-two">
        <Link to={`/blog/${item.slug}`}>
          <img src={`${process.env.REACT_APP_API_URL}/api/images/${item.imageCover}`} alt={item.articleTitle} />
        </Link>
      </div>

      <div className="blog-content-two">
        <Link to={`/blog/${item.slug}`} className="tag">
          {item.category}
        </Link>

        <div className="blog-meta">
          <ul className="list-wrap">
            <li>
              <i className="fas fa-calendar-alt"></i>
              {new Date(item.publishedAt).toLocaleDateString('en-GB', {
                day: '2-digit',
                month: 'short',
                year: 'numeric'
              }).replace(/ /g, '.')}
            </li>
            <li>
              <i className="far fa-user"></i>
              <Link to="/blog.html">{item.articleAuthor}</Link>
            </li>
          </ul>
        </div>

        <h2 className="title">
          <Link to={`/blog/${item.slug}`}>{item.articleTitle}</Link>
        </h2>

        <Link to={`/blog/${item.slug}`} className="btn btn-two">
          Read more
        </Link>
      </div>
    </div>
  );
};

export default BlogAreaTwoItem;
