import React from "react";
import BlogDetailsComments from "./BlogDetailsComments";
import BlogDetailsForm from "./BlogDetailsForm";
import BlogSidebar from "../BlogSidebar/BlogSidebar";

const BlogDetailsArea = ({ post, comments, onCommentSubmit, onCommentChange, formData }) => {
  return (
    <section className="blog-details-area pt-130 pb-130">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="blog-details-wrap">
              <div className="blog-item-two inner-blog-item">
                <div className="blog-thumb-two blog-details-thumb">
                  <img src={`${process.env.REACT_APP_API_URL}/api/images/${post.imageCover}`} alt={post.articleTitle} />
                </div>
                <div className="blog-content-two blog-details-content">
                  <div className="blog-meta">
                    <ul className="list-wrap">
                      <li>
                        <i className="fas fa-calendar-alt"></i>
                        {new Date(post.publishedAt).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).replace(/ /g, '.')}
                      </li>
                      <li>
                        <i className="far fa-user"></i>
                        {post.articleAuthor}
                      </li>
                    </ul>
                  </div>

                  <h2 className="title">{post.articleTitle}</h2>
                  <div dangerouslySetInnerHTML={{ __html: post.articleContent }} />

                  <div className="blog-details-bottom">
                    {/* BlogDetailsBottom component if needed */}
                  </div>
                </div>
              </div>

              <div className="comments-wrap">
                <h4 className="comments-wrap-title">Comments ({comments.length})</h4>
                <BlogDetailsComments comments={comments} />
              </div>

              <div className="comment-respond">
                <h3 className="comment-reply-title">Leave A Comment</h3>
                <BlogDetailsForm 
                  onSubmit={onCommentSubmit} 
                  onChange={onCommentChange} 
                  formData={formData} 
                />
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-8">
            <BlogSidebar />
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogDetailsArea;
