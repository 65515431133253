import React from "react";
import TeamDetailsForm from "./TeamDetailsForm";
import TeamDetailsSidebar from "./TeamDetailsSidebar";

const TeamDetailsArea = ({ teamMember }) => {
  if (!teamMember) return null;

  return (
    <section className="team-details-area pt-130 pb-100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-8">
            <TeamDetailsSidebar teamMember={teamMember} />
          </div>

          <div className="col-lg-8">
            <div className="team-details-content">
              <p>{teamMember.bio}</p>

              {/* Uncomment and update this section when you have progress data */}
              {/* <TeamDetailsProgress progress={teamMember.progress} /> */}

              <div className="team-details-form">
                <h2 className="title">
                  Feel Free to Contact us
                </h2>

                <TeamDetailsForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeamDetailsArea;
