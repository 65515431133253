import React, { useState, useEffect } from "react";

const ContactMapArea = () => {
  const [mainGoogleMapsLink, setMainGoogleMapsLink] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchContactData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/contact`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setMainGoogleMapsLink(data.data.contact.mainGoogleMapsLink);
      } catch (error) {
        console.error('Error fetching contact data:', error);
        setError('Failed to fetch contact data.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchContactData();
  }, []);

  if (isLoading) {
    return <div>Loading map...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!mainGoogleMapsLink) {
    return null;
  }

  return (
    <div id="contact-map">
      <iframe
        src={mainGoogleMapsLink}
        allowFullScreen
        loading="lazy"
      ></iframe>
    </div>
  );
};

export default ContactMapArea;
