import React from "react";
import Odometer from "react-odometerjs";
import "odometer/themes/odometer-theme-default.css";

const OdometerItem = ({ amount }) => {
  return (
    <Odometer
      value={amount}
      format="(,ddd).dd"
      duration={1000}
      animation="count"
    />
  );
};

export default OdometerItem;
