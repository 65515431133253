import React, { useEffect, useState } from "react";
import BreadcrumbArea from "../../components/BreadcrumbArea/BreadcrumbArea";
import TeamAll from "../../components/Team/TeamAll";
import Layout from "../../layouts/Layout";
import { gsapTitleAnimation } from "../../lib/gsap-lib/gsapTitleAnimation";

const TeamPage = () => {
  const [teamData, setTeamData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    gsapTitleAnimation();

    const fetchTeamData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/team/teams`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setTeamData(data.data.teamMembers);
      } catch (error) {
        console.error('Error fetching team data:', error);
        setError('Failed to fetch team data.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchTeamData();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <Layout header={1} footer={1}>
      <BreadcrumbArea title={"Team"} subtitle={"Team"} />
      <TeamAll teamData={teamData} />
    </Layout>
  );
};

export default TeamPage;
